<template>
  <div class="settings">
    <div class="flex items-center">
      <span class="mr-3 settings__title-inner">{{ $t('info.dialogSettingsTitle') }}</span>

      <vx-tooltip class="flex items-center" position="top" :title="''" :text="$t('info.dialogSettingsTitleInfo')">
        <img :src="infoImg" style="display: inline-block; width: 15px" />
      </vx-tooltip>
    </div>

    <vx-card v-if="dialog" no-shadow class="mt-3">
      <div class="settings__item">
        <vs-switch color="#275D73" v-model="switchVisibility" :disabled="switchVisibility" @input="updateDialogVisibility" />

        <div class="settings__item__caption">{{ $t('info.alwaysOn') }}</div>
      </div>

      <!-- <div class="mt-3 settings__item">
        <vs-switch
          color="#275D73"
          v-model="isEnabledWorkingDaysSettings"
          :disabled="isEnabledWorkingDaysSettings"
          @input="updateIsEnabledWorkingDaysSettings"
        />

        <div class="settings__item__caption">{{ $t('info.workingDays') }}</div>
      </div> -->

      <DialogSettingsAvailabileTimeslots v-if="isEnabledWorkingDaysSettings" settingsKey="workingDaysSettings" :dialog="dialog" :isCustomDays="false" />

      <div class="mt-3 settings__item">
        <vs-switch color="#275D73" v-model="isEnabledCustomTimeSettings" :disabled="isEnabledCustomTimeSettings" @input="updateIsEnabledCustomTimeSettings" />

        <div class="settings__item__caption">{{ $t('info.setCustomTime') }}</div>
      </div>

      <DialogSettingsAvailabileTimeslots v-if="isEnabledCustomTimeSettings" settingsKey="customTimeSettings" :dialog="dialog" :isCustomDays="true" />

      <div class="mt-3 settings__item settings__item_bordered">
        <vs-switch color="#275D73" v-model="isDisableInMobiles" @input="updateIsDisableInMobiles" />

        <div class="settings__item__caption">{{ $t('info.hideDialogOnMobile') }}</div>
      </div>

      <div class="settings__item settings__item_bordered">
        <vs-switch color="#275D73" v-model="isHiddenWhenNoAgentOnline" @input="updateIsHiddenWhenNoAgentOnline" />

        <div class="settings__item__caption">{{ $t('info.hideDialogWhileNoAgentIsCurrentlyOnline') }}</div>
      </div>

      <div class="pb-0 settings__item">
        <vs-switch color="#275D73" v-model="isShowedUpContactFormWhenNoAgentOnline" @input="updateIsShowedUpContactFormWhenNoAgentOnline" />

        <div class="settings__item__caption">{{ $t('info.directlyShowUpContactFormWhenNoAgentIsOnline') }}</div>
      </div>

      <div class="mt-3 pb-0 settings__item">
        <vs-switch color="#275D73" v-model="isNeverShowUpContactForm" @input="updateIsNeverShowUpContactForm" />

        <div class="settings__item__caption">{{ $t('info.neverShowUpContactForm') }}</div>
      </div>

      <div class="mt-3 settings__item settings__item_bordered">
        <div class="flex w-full flex-col">
          <div class="mb-2">{{ $t('vue.displayAfterSeconds') }}</div>
          <div class="flex flex-row items-center w-full">
            <vs-input class="mr-2" type="number" v-model="dialog.visibleTimeout" @blur="updateVisibleTimeout" />
            <vx-tooltip :text="$t('info.afterHowManySecondDialogDisplay')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
          <div class="mt-2 mb-2">{{ $t('vue.displayContactFormAfterSeconds') }}</div>

          <div class="flex flex-row items-center w-full">
            <vs-input
              class="mr-2"
              name="contactFormTimeout"
              :data-vv-as="' '"
              type="number"
              v-validate="'min_value:15|max_value:300'"
              v-model="dialog.contactFormTimeout"
              @blur="updateContactFormTimeout"
            />
            <vx-tooltip :text="$t('info.afterHowManySecondContactFormDisplay')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('contactFormTimeout')">{{ errors.first('contactFormTimeout') }}</span>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import DialogSettingsAvailabileTimeslots from '@/views/pages/dialog/DialogSettingsAvailabileTimeslots'

export default {
  components: {
    DialogSettingsAvailabileTimeslots
  },

  data() {
    return {
      switchVisibility: false,
      isEnabledCustomTimeSettings: false,
      isEnabledWorkingDaysSettings: false,
      isDisableInMobiles: false,
      isHiddenWhenNoAgentOnline: false,
      isShowedUpContactFormWhenNoAgentOnline: false,
      isNeverShowUpContactForm: false,

      dialog: null,
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  watch: {
    isHiddenWhenNoAgentOnline() {
      if (this.isHiddenWhenNoAgentOnline) {
        this.isShowedUpContactFormWhenNoAgentOnline = false
        this.updateIsShowedUpContactFormWhenNoAgentOnline()
      }
    },
    isShowedUpContactFormWhenNoAgentOnline() {
      if (this.isShowedUpContactFormWhenNoAgentOnline) {
        this.isHiddenWhenNoAgentOnline = false
        this.updateIsHiddenWhenNoAgentOnline()

        this.isNeverShowUpContactForm = false
        this.updateIsNeverShowUpContactForm()
      }
    },
    isNeverShowUpContactForm() {
      if (this.isNeverShowUpContactForm) {
        this.isShowedUpContactFormWhenNoAgentOnline = false
        this.updateIsShowedUpContactFormWhenNoAgentOnline()
      }
    }
  },

  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },

  async mounted() {
    await this.$vs.loading()

    await this.getDialog()

    await this.$vs.loading.close()
  },

  methods: {
    async getDialog() {
      const dialogId = this.$route.params.id

      if (!dialogId) {
        return
      }

      const dialogRef = await this.$db.collection('dialogs').doc(dialogId).get()

      this.dialog = dialogRef.data()
      this.dialog.id = dialogRef.id
      this.switchVisibility = this.switchVisibility || this.dialog.switchVisibility || false
      this.isEnabledCustomTimeSettings = !!(this.dialog.customTimeSettings && this.dialog.customTimeSettings.enabled)
      this.isEnabledWorkingDaysSettings = !!(this.dialog.workingDaysSettings && this.dialog.workingDaysSettings.enabled)
      this.isDisableInMobiles = this.dialog.disableInMobiles
      this.isHiddenWhenNoAgentOnline = this.dialog.isHiddenWhenNoAgentOnline
      this.isShowedUpContactFormWhenNoAgentOnline = this.dialog.isShowedUpContactFormWhenNoAgentOnline
      this.isNeverShowUpContactForm = this.dialog.isNeverShowUpContactForm
    },

    async updateDialogVisibility() {
      await this.$vs.loading()

      const data = {
        switchVisibility: this.switchVisibility
      }

      if (this.switchVisibility) {
        this.isEnabledWorkingDaysSettings = false
        this.isEnabledCustomTimeSettings = false
        data.workingDaysSettings = {
          enabled: this.isEnabledWorkingDaysSettings
        }
        data.customTimeSettings = {
          enabled: this.isEnabledCustomTimeSettings
        }
      }

      await this.$db.collection('dialogs').doc(this.dialog.id).set(data, { merge: true })

      await this.getDialog()

      await this.$vs.loading.close()
    },

    async updateIsEnabledWorkingDaysSettings() {
      await this.$vs.loading()

      if (this.isEnabledWorkingDaysSettings) {
        this.isEnabledCustomTimeSettings = false
      }

      const data = {
        workingDaysSettings: {
          enabled: this.isEnabledWorkingDaysSettings
        }
      }

      if (this.isEnabledWorkingDaysSettings) {
        this.switchVisibility = false
        this.isEnabledCustomTimeSettings = false
        data.switchVisibility = this.switchVisibility
        data.customTimeSettings = {
          enabled: this.isEnabledCustomTimeSettings
        }
      }

      await this.$db.collection('dialogs').doc(this.dialog.id).set(data, { merge: true })

      await this.getDialog()

      await this.$vs.loading.close()
    },

    async updateIsEnabledCustomTimeSettings() {
      await this.$vs.loading()

      if (this.isEnabledCustomTimeSettings) {
        this.isEnabledWorkingDaysSettings = false
      }

      const data = {
        customTimeSettings: {
          enabled: this.isEnabledCustomTimeSettings
        }
      }

      if (this.isEnabledCustomTimeSettings) {
        this.switchVisibility = false
        this.isEnabledWorkingDaysSettings = false
        data.switchVisibility = this.switchVisibility
        data.workingDaysSettings = {
          enabled: this.isEnabledWorkingDaysSettings
        }
      }

      await this.$db.collection('dialogs').doc(this.dialog.id).set(data, { merge: true })

      await this.getDialog()

      await this.$vs.loading.close()
    },

    async updateIsDisableInMobiles() {
      await this.$vs.loading()

      await this.$db.collection('dialogs').doc(this.dialog.id).set({ disableInMobiles: this.isDisableInMobiles }, { merge: true })

      await this.$vs.loading.close()
    },

    async updateIsHiddenWhenNoAgentOnline() {
      await this.$vs.loading()

      await this.$db.collection('dialogs').doc(this.dialog.id).set({ isHiddenWhenNoAgentOnline: this.isHiddenWhenNoAgentOnline }, { merge: true })

      await this.$vs.loading.close()
    },

    async updateIsShowedUpContactFormWhenNoAgentOnline() {
      await this.$vs.loading()

      await this.$db
        .collection('dialogs')
        .doc(this.dialog.id)
        .set({ isShowedUpContactFormWhenNoAgentOnline: this.isShowedUpContactFormWhenNoAgentOnline }, { merge: true })

      await this.$vs.loading.close()
    },

    async updateIsNeverShowUpContactForm() {
      await this.$vs.loading()

      await this.$db
        .collection('dialogs')
        .doc(this.dialog.id)
        .set({ isNeverShowUpContactForm: this.isNeverShowUpContactForm }, { merge: true })

      await this.$vs.loading.close()
    },

    async updateVisibleTimeout() {
      const result = await this.$validator.validateAll()
      if (!result) {
        return
      }

      this.$db
        .collection('dialogs')
        .doc(this.dialog.id)
        .set({ visibleTimeout: Number(this.dialog.visibleTimeout) }, { merge: true })
      this.$store.commit('UPDATE_DIALOG', {
        ...this.dialog
      })
    },

    async updateContactFormTimeout() {
      const result = await this.$validator.validateAll()
      if (!result) {
        return
      }

      this.$db
        .collection('dialogs')
        .doc(this.dialog.id)
        .set({ contactFormTimeout: Number(this.dialog.contactFormTimeout) }, { merge: true })
      this.$store.commit('UPDATE_DIALOG', {
        ...this.dialog
      })
    }
  }
}
</script>

<style lang="scss">
.flex-col {
  flex-direction: column;
}
.settings {
  .vs-switch {
    min-width: 40px;
    height: 20px;

    &--circle {
      left: 4px;
      top: 4px;
      width: 12px;
      height: 12px;
    }
  }
}
</style>

<style lang="scss" scoped>
.settings {
  &__title-inner {
    color: #262629;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
  }

  &__item {
    display: flex;
    align-items: center;

    &__caption {
      margin-left: 10px;
      color: rgb(87, 87, 87);
      font-size: 14px;
      font-weight: 500;
    }

    &_bordered {
      padding: 15px 0;
      border-top: 1px solid rgb(196, 196, 196);
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings" },
    [
      _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _c("span", { staticClass: "mr-3 settings__title-inner" }, [
            _vm._v(_vm._s(_vm.$t("info.dialogSettingsTitle"))),
          ]),
          _c(
            "vx-tooltip",
            {
              staticClass: "flex items-center",
              attrs: {
                position: "top",
                title: "",
                text: _vm.$t("info.dialogSettingsTitleInfo"),
              },
            },
            [
              _c("img", {
                staticStyle: { display: "inline-block", width: "15px" },
                attrs: { src: _vm.infoImg },
              }),
            ]
          ),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            "vx-card",
            { staticClass: "mt-3", attrs: { "no-shadow": "" } },
            [
              _c(
                "div",
                { staticClass: "settings__item" },
                [
                  _c("vs-switch", {
                    attrs: { color: "#275D73", disabled: _vm.switchVisibility },
                    on: { input: _vm.updateDialogVisibility },
                    model: {
                      value: _vm.switchVisibility,
                      callback: function ($$v) {
                        _vm.switchVisibility = $$v
                      },
                      expression: "switchVisibility",
                    },
                  }),
                  _c("div", { staticClass: "settings__item__caption" }, [
                    _vm._v(_vm._s(_vm.$t("info.alwaysOn"))),
                  ]),
                ],
                1
              ),
              _vm.isEnabledWorkingDaysSettings
                ? _c("DialogSettingsAvailabileTimeslots", {
                    attrs: {
                      settingsKey: "workingDaysSettings",
                      dialog: _vm.dialog,
                      isCustomDays: false,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-3 settings__item" },
                [
                  _c("vs-switch", {
                    attrs: {
                      color: "#275D73",
                      disabled: _vm.isEnabledCustomTimeSettings,
                    },
                    on: { input: _vm.updateIsEnabledCustomTimeSettings },
                    model: {
                      value: _vm.isEnabledCustomTimeSettings,
                      callback: function ($$v) {
                        _vm.isEnabledCustomTimeSettings = $$v
                      },
                      expression: "isEnabledCustomTimeSettings",
                    },
                  }),
                  _c("div", { staticClass: "settings__item__caption" }, [
                    _vm._v(_vm._s(_vm.$t("info.setCustomTime"))),
                  ]),
                ],
                1
              ),
              _vm.isEnabledCustomTimeSettings
                ? _c("DialogSettingsAvailabileTimeslots", {
                    attrs: {
                      settingsKey: "customTimeSettings",
                      dialog: _vm.dialog,
                      isCustomDays: true,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-3 settings__item settings__item_bordered" },
                [
                  _c("vs-switch", {
                    attrs: { color: "#275D73" },
                    on: { input: _vm.updateIsDisableInMobiles },
                    model: {
                      value: _vm.isDisableInMobiles,
                      callback: function ($$v) {
                        _vm.isDisableInMobiles = $$v
                      },
                      expression: "isDisableInMobiles",
                    },
                  }),
                  _c("div", { staticClass: "settings__item__caption" }, [
                    _vm._v(_vm._s(_vm.$t("info.hideDialogOnMobile"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "settings__item settings__item_bordered" },
                [
                  _c("vs-switch", {
                    attrs: { color: "#275D73" },
                    on: { input: _vm.updateIsHiddenWhenNoAgentOnline },
                    model: {
                      value: _vm.isHiddenWhenNoAgentOnline,
                      callback: function ($$v) {
                        _vm.isHiddenWhenNoAgentOnline = $$v
                      },
                      expression: "isHiddenWhenNoAgentOnline",
                    },
                  }),
                  _c("div", { staticClass: "settings__item__caption" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("info.hideDialogWhileNoAgentIsCurrentlyOnline")
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pb-0 settings__item" },
                [
                  _c("vs-switch", {
                    attrs: { color: "#275D73" },
                    on: {
                      input: _vm.updateIsShowedUpContactFormWhenNoAgentOnline,
                    },
                    model: {
                      value: _vm.isShowedUpContactFormWhenNoAgentOnline,
                      callback: function ($$v) {
                        _vm.isShowedUpContactFormWhenNoAgentOnline = $$v
                      },
                      expression: "isShowedUpContactFormWhenNoAgentOnline",
                    },
                  }),
                  _c("div", { staticClass: "settings__item__caption" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "info.directlyShowUpContactFormWhenNoAgentIsOnline"
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-3 pb-0 settings__item" },
                [
                  _c("vs-switch", {
                    attrs: { color: "#275D73" },
                    on: { input: _vm.updateIsNeverShowUpContactForm },
                    model: {
                      value: _vm.isNeverShowUpContactForm,
                      callback: function ($$v) {
                        _vm.isNeverShowUpContactForm = $$v
                      },
                      expression: "isNeverShowUpContactForm",
                    },
                  }),
                  _c("div", { staticClass: "settings__item__caption" }, [
                    _vm._v(_vm._s(_vm.$t("info.neverShowUpContactForm"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-3 settings__item settings__item_bordered" },
                [
                  _c("div", { staticClass: "flex w-full flex-col" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("vue.displayAfterSeconds"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex flex-row items-center w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "mr-2",
                          attrs: { type: "number" },
                          on: { blur: _vm.updateVisibleTimeout },
                          model: {
                            value: _vm.dialog.visibleTimeout,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialog, "visibleTimeout", $$v)
                            },
                            expression: "dialog.visibleTimeout",
                          },
                        }),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t(
                                "info.afterHowManySecondDialogDisplay"
                              ),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mt-2 mb-2" }, [
                      _vm._v(
                        _vm._s(_vm.$t("vue.displayContactFormAfterSeconds"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex flex-row items-center w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "min_value:15|max_value:300",
                              expression: "'min_value:15|max_value:300'",
                            },
                          ],
                          staticClass: "mr-2",
                          attrs: {
                            name: "contactFormTimeout",
                            "data-vv-as": " ",
                            type: "number",
                          },
                          on: { blur: _vm.updateContactFormTimeout },
                          model: {
                            value: _vm.dialog.contactFormTimeout,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialog, "contactFormTimeout", $$v)
                            },
                            expression: "dialog.contactFormTimeout",
                          },
                        }),
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t(
                                "info.afterHowManySecondContactFormDisplay"
                              ),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("contactFormTimeout"),
                            expression: "errors.has('contactFormTimeout')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("contactFormTimeout")))]
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }